import {createRouter, createWebHistory} from 'vue-router'


//路由数组
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home/home.vue'),
    }, {
        path: '/look',
        name: 'look',
        component: () => import('@/pages/look/look.vue'),
    }, {
        path: '/hotspot',
        name: 'hotspot',
        component: () => import('@/pages/hotspot/hotspot.vue'),
    }, {
        path: '/valuable',
        name: 'valuable',
        component: () => import('@/pages/valuable/valuable.vue'),
    }, {
        path: '/report',
        name: 'report',
        component: () => import('@/pages/report/report.vue'),
    }, {
        path: '/hotspotDetails',
        name: 'hotspotDetails',
        component: () => import('@/pages/hotspotDetails/hotspotDetails.vue'),
    }, {
        path: '/lookDetails',
        name: 'lookDetails',
        component: () => import('@/pages/lookDetails/lookDetails.vue'),
    }, {
        path: '/newsList',
        name: 'newsList',
        component: () => import('@/pages/newsList/newsList.vue'),
    }, {
        path: '/HousesDetails',
        name: 'HousesDetails',
        component: () => import('@/pages/HousesDetails/HousesDetails.vue'),
        children: [
            {
                path: '/HousesHome',
                name: 'HousesHome',
                component: () => import('@/pages/HousesDetails/HousesHome/HousesHome.vue'),
            }, {
                path: '/HousesInfo',
                name: 'HousesInfo',
                component: () => import('@/pages/HousesDetails/HousesInfo/HousesInfo.vue'),
            }, {
                path: '/HousesShow',
                name: 'HousesShow',
                component: () => import('@/pages/HousesDetails/HousesShow/HousesShow.vue'),
            }, {
                path: '/HousesShowInfo',
                name: 'HousesShowInfo',
                component: () => import('@/pages/HousesDetails/HousesShowInfo/HousesShowInfo.vue'),
            }, {
                path: '/HousesSurrounding',
                name: 'HousesSurrounding',
                component: () => import('@/pages/HousesDetails/HousesSurrounding/HousesSurrounding.vue'),
            }, {
                path: '/HousesAlbum',
                name: 'HousesAlbum',
                component: () => import('@/pages/HousesDetails/HousesAlbum/HousesAlbum.vue'),
            }, {
                path: '/HousesDynamic',
                name: 'HousesDynamic',
                component: () => import('@/pages/HousesDetails/HousesDynamic/HousesDynamic.vue'),
            }
        ]
    }, {
        path: '/my',
        name: 'my',
        component: () => import('@/pages/my/my.vue'),
        children: [
            {
                path: '/MessageList',
                name: 'MessageList',
                component: () => import('@/pages/my/MessageList/MessageList.vue'),
            },
            {
                path: '/MessageInfo/:messageData',
                name: 'MessageInfo',
                component: () => import('@/pages/my/MessageInfo/MessageInfo.vue'),
            }, {
                path: '/MyReport',
                name: 'MyReport',
                component: () => import('@/pages/my/MyReport/MyReport.vue'),
            }, {
                path: '/MyReportInfo',
                name: 'MyReportInfo',
                component: () => import('@/pages/my/MyReportInfo/MyReportInfo.vue'),
            }, {
                path: '/CommissionUpload',
                name: 'CommissionUpload',
                component: () => import('@/pages/my/CommissionUpload/CommissionUpload.vue'),
            }, {
                path: '/CommissionRecord',
                name: 'CommissionRecord',
                component: () => import('@/pages/my/CommissionRecord/CommissionRecord.vue'),
            }, {
                path: '/BrowseRecord',
                name: 'BrowseRecord',
                component: () => import('@/pages/my/BrowseRecord/BrowseRecord.vue'),
            }, {
                path: '/CollectHouses',
                name: 'CollectHouses',
                component: () => import('@/pages/my/CollectHouses/CollectHouses.vue'),
            }, {
                path: '/ChangePassword',
                name: 'ChangePassword',
                component: () => import('@/pages/my/ChangePassword/ChangePassword.vue'),
            }
        ]
    }
]

//路由对象
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes

})

export default router
