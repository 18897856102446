import axios from 'axios'

import {ElMessage} from 'element-plus'

axios.defaults.timeout = 50000

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    switch (config.method) {
        case 'get':
            config.params = {
                ...config.params,
                member_id: localStorage.getItem('MEMBER_ID'),
                city_code: localStorage.getItem('CITY_CODE')
            }
            break
        case 'post':
            if (config.data instanceof FormData) {
                config.data.append('member_id', localStorage.getItem('MEMBER_ID'))
                config.data.append('city_code', localStorage.getItem('CITY_CODE'))
            } else {
                config.data = {
                    ...config.data,
                    member_id: localStorage.getItem('MEMBER_ID'),
                    city_code: localStorage.getItem('CITY_CODE')
                }
            }
            break
    }
    config.params = {
        ...config.params,
        is_pc: 1
    }
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.config.url === '/ws/place/v1/search') {
        return response.data
    }
    if (response.data.code !== 0) {
        ElMessage({
            message: response.data.msg,
            type: 'error',
        })
        return Promise.reject(response.data)
    }
    return response.data
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么

    ElMessage({
        message: '服务器错误请稍后再试',
        type: 'error',
    })
    return Promise.reject(error.response)
})


export default axios

