<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  async mounted() {
    let res = await this.http({
      url: '/api/index/pub'
    })
    localStorage.setItem('pc_index_business_license', res.data.pc_index_business_license)
    localStorage.setItem('pc_index_company_name', res.data.pc_index_company_name)
    localStorage.setItem('pc_index_work_permit', res.data.pc_index_work_permit)
    localStorage.setItem('pc_index_contact', res.data.pc_index_contact)
    localStorage.setItem('pc_index_address', res.data.pc_index_address)
    localStorage.setItem('pc_index_email', res.data.pc_index_email)
    localStorage.setItem('site_beian', res.data.site_beian)
    localStorage.setItem('site_copyright', res.data.site_copyright)
    let member = localStorage.getItem('MEMBER')
    if (member !== null) {
      member = JSON.parse(member)
      let res2 = await this.http({
        url: '/api/index/index'
      })
      member.can_receptionist = res2.data.can_receptionist
      localStorage.setItem('MEMBER', JSON.stringify(member))

    }
  }
}
</script>

<style lang="scss">
@import "./style/style.css";
/*面包屑*/
.crumbs-info {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: 54px;
  background-color: #F8F8F8;
  z-index: 100;

  .crumbs-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    .crumbs {
      display: flex;
      align-items: center;
      height: 100%;

      .item {
        display: flex;
        align-items: center;
        margin-right: 10px;

        a {
          font-size: 14px;
          color: #666666;
        }

        a:hover {
          color: #333333;
        }

        .right-arrows {
          width: 8px;
          height: 8px;
          border-color: #666666;
        }
      }

      .active {
        a {
          color: #333333;
        }
      }
    }

    .search {
      display: flex;
      align-items: center;

      .search-text {
        display: flex;
        align-items: center;
        width: 353px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 5px;

        .text {
          display: flex;
          align-items: center;
          padding: 0 15px;
          box-sizing: border-box;
          width: 90%;
          height: 100%;

          input {
            width: 100%;
            height: 100%;
            font-size: 14px;
            color: #333333;
            outline: none;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 100%;
          background-color: #0F375A;
          border-radius: 0 5px 5px 0;
          cursor: pointer;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .report-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 68px;
        height: 30px;
        background-color: #0F375A;
        border-radius: 5px;
        margin-left: 20px;

        span {
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
  }
}

/*分页*/
.paging-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;

  .pagination {
    display: flex;
    align-items: center;

    .num {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin-right: 10px;
      border: 1px solid #0F375A;
      border-radius: 100%;

      span {
        font-size: 14px;
        color: #0F375A;
      }
    }

    .active {
      background-color: #0F375A;

      span {
        color: #ffffff;
      }
    }
  }

  .paging {
    display: flex;
    align-items: center;

    .cut {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin: 0 10px;
      cursor: pointer;
      background-color: #0F375A;
      border-radius: 100%;

      .left {
        width: 8px;
        height: 8px;
        margin-left: 5px;
        border-left: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        transform: rotate(45deg);
      }

      .right {
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        transform: rotate(-45deg);
      }
    }

    .cut:hover {
      background-color: #F9C03D;
    }
  }
}

/*更多箭头*/
.right-arrows {
  width: 10px;
  height: 10px;
  border-right: 1px solid #999999;
  border-bottom: 1px solid #999999;
  transform: rotate(-45deg);
}
</style>
